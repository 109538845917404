<template>
    <div class="operation">
        <!--  -->
        <div class="head padd20 borbut20">
            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">端口账户：</div>
                    <el-input v-model="input" placeholder="请输入端口账户"></el-input>
                </div>
                <div class="searchdan">
                    <div class="title">端口简称：</div>
                    <el-input v-model="inputs" placeholder="请输入端口简称"></el-input>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
            <!-- <div class="zhong">
                <el-input v-model="input" placeholder="请输入端口账户" class="in"></el-input>
                <el-input v-model="inputs" placeholder="请输入端口简称"></el-input>
                <el-button type="primary" icon="el-icon-search" @click="sousuo()">搜索</el-button>
            </div> -->
            <div></div>
        </div>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">端口管理</div>
                <el-button type="primary" id="AddPort" @click="addzhanghu($event)" class="yinyinga" icon="el-icon-plus">新增端口</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID" min-width="40%">
                </el-table-column>
                <el-table-column prop="advertiser_account" label="端口账户">
                </el-table-column>
                <el-table-column prop="advertiser_name" label="端口简称">
                </el-table-column>
                <el-table-column prop="advertiser_account_id" label="端口ID">
                    <template slot-scope="scope">
                        <span>{{scope.row.advertiser_account_id ? scope.row.advertiser_account_id:'- -'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="advertiser_full_name" label="端口全称">
                    <template slot-scope="scope">
                        <span>{{scope.row.advertiser_full_name ? scope.row.advertiser_full_name:'- -' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="sto_contrary_to_port_rebates" label="对公自运营返点">
                    <template slot-scope="scope">
                        {{scope.row.sto_contrary_to_port_rebates ? scope.row.sto_contrary_to_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="sto_private_port_rebates" label="对私自运营返点">
                    <template slot-scope="scope">
                        {{scope.row.sto_private_port_rebates ? scope.row.sto_private_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="goo_contrary_to_port_rebates" label="对公代运营返点">
                    <template slot-scope="scope">
                        {{scope.row.goo_contrary_to_port_rebates ? scope.row.goo_contrary_to_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="goo_private_port_rebates" label="对私代运营返点">
                    <template slot-scope="scope">
                        {{scope.row.goo_private_port_rebates ? scope.row.goo_private_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="channel_contrary_to_port_rebates" label="对公渠道返点">
                    <template slot-scope="scope">
                        {{scope.row.channel_contrary_to_port_rebates ? scope.row.channel_contrary_to_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="channel_private_port_rebates" label="对私渠道返点">
                    <template slot-scope="scope">
                        {{scope.row.channel_private_port_rebates ? scope.row.channel_private_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="w_channel_contrary_to_port_rebates" label="平冲对公返点">
                    <template slot-scope="scope">
                        {{scope.row.w_channel_contrary_to_port_rebates ? scope.row.w_channel_contrary_to_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="w_channel_private_port_rebates" label="平冲对私返点">
                    <template slot-scope="scope">
                        {{scope.row.w_channel_private_port_rebates ? scope.row.w_channel_private_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="cid_channel_contrary_to_port_rebates" label="cid对公返点">
                    <template slot-scope="scope">
                        {{scope.row.cid_channel_contrary_to_port_rebates ? scope.row.cid_channel_contrary_to_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="cid_channel_private_port_rebates" label="cid对私返点">
                    <template slot-scope="scope">
                        {{scope.row.cid_channel_private_port_rebates ? scope.row.cid_channel_private_port_rebates:'- -' }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComPort">编辑</el-button>
                        <!-- <el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
                            <el-button size="mini" slot="reference" type="danger">删除</el-button>
                        </el-popconfirm> -->
                        <el-button type="primary" @click="onSettlement(scope.row)">财务结算</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <!-- <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination> -->
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            </div>
        </div>

        <!-- 弹窗 -->
        <div>
            <AddPort ref="AddPort" :msg="activeName" @zhanghu="zhanghu" @bianji="bianji" v-if="activeName == 'AddPort'"></AddPort>
            <ComPort ref="ComPort" :id="operating_id" :msg="activeName" @zhanghu="zhanghu" @bianji="bianji" v-if="activeName == 'ComPort'"></ComPort>
            <Settlementdialog ref="Settlementdialog"></Settlementdialog>
        </div>
    </div>
</template>

<script>
import ComPort from "./ComPort.vue";
import AddPort from "./Addport.vue";
import Settlementdialog from "./settlementdialog.vue";
export default {
    data() {
        return {
            paramData:{},//表格参数
            input: "", //搜索内容
            inputs: "",
            operating_id: "", //运营官id
            tableData: [], //显示运营列表
            per_page: null, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页
            page: "1",
            activeName: "",
            param:{},
        };
    },
    mounted() {
        this.ongetAdvertiserList();
    },
    components: {
        AddPort,
        ComPort,
        Settlementdialog,
    },
    methods: {
        //增加账户
        addzhanghu(e, index) {
            this.activeName = e.currentTarget.id;
        },
        //   创建账户
        zhanghu(data) {
            // console.log(1111);
            this.activeName = "";
            // if (data == undefined) {
            //     this.activeName = "";
            // } else {
            //     this.groupList[this.index].agent_account.push(data);
            //     this.activeName = "";
            // }
        },
        // 获取端口列表
        // getAdvertiserList() {
        //     var param = {
        //         page: 1,
        //     };
        //     this.$service.get(this.$api.getAdvertiserList, param, (res) => {
        //         if (res.code == "200") {
        //             this.tableData = res.data.data;
        //             // console.log(this.tableData);
        //             this.lvtotal = res.data.total;
        //         }
        //     });
        // },


        //搜索
        onButtonInquire(){
            this.paramData = {
                advertiser_account: this.input,
                advertiser_name: this.inputs,
            }
            this.paramPage = 1
            this.ongetAdvertiserList()
        },
        // 端口列表
        ongetAdvertiserList(val) {
            this.tableData = [];
            var param = this.paramData
            param.page = this.paramPage
            this.$service.get(this.$api.getAdvertiserList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                    this.per_page = res.data.per_page
                }
            });
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetAdvertiserList()
        },
        // 筛选
        filterTag(value, row) {
            return row.tag === value;
        },
        // 编辑
        handleEdit(index, row, e) {
            this.activeName = e.currentTarget.id;
            this.operating_id = row.id;
        },
        //编辑回传
        bianji() {
            this.ongetAdvertiserList()
            // this.getAdvertiserList();
            this.activeName = "";
        },
        // 删除
        // handleDelete(index, row) {
        //     console.log(index, row);
        // },
        // 分页
        
        //结算
        onSettlement(row) {
            this.$refs.Settlementdialog.onDialog(row);
        },
    },
};
</script>

<style lang="scss" scoped>
.operation {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 50px;
            height: 50px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
    .in {
        margin-right: 25px;
    }
}
</style>
<style lang="scss">
.operation {
    .el-input {
        width: 180px;
    }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
}
</style>