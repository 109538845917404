<template>
    <div class="Addport">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="配置端口" :visible.sync="yunyingtan" width="670px" :closeOnClickModal='false' @close="closeDialog()">
                <div class="chuangjian"></div>
                <el-form :model="form">
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            端口账户
                        </div>
                        <el-input v-model="form.advertiser_account" autocomplete="off" placeholder="请输入端口账户"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            端口账户ID
                        </div>
                        <el-input v-model="form.advertiser_account_id" autocomplete="off" placeholder="请输入端口账户ID"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            端口简称
                        </div>
                        <el-input v-model="form.advertiser_name" autocomplete="off" placeholder="请输入 端口简称"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            端口密码
                        </div>
                        <el-input v-model="form.advertiser_pwd" autocomplete="off" placeholder="请输入端口密码"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            端口全称
                        </div>
                        <el-input v-model="form.advertiser_full_name" autocomplete="off" placeholder="请输入端口全称"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            对公自运营返点
                        </div>
                        <el-input v-model="form.sto_contrary_to_port_rebates" autocomplete="off" placeholder="请输入对公自运营返点"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            对私自运营返点
                        </div>
                        <el-input v-model="form.sto_private_port_rebates" autocomplete="off" placeholder="请输入对私自运营返点"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            对公代运营返点
                        </div>
                        <el-input v-model="form.goo_contrary_to_port_rebates" autocomplete="off" placeholder="请输入对公代运营返点"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            对私代运营返点
                        </div>
                        <el-input v-model="form.goo_private_port_rebates" autocomplete="off" placeholder="请输入对私代运营返点"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            对公渠道返点
                        </div>
                        <el-input v-model="form.channel_contrary_to_port_rebates" autocomplete="off" placeholder="请输入对公渠道返点"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            对私渠道返点
                        </div>
                        <el-input v-model="form.channel_private_port_rebates" autocomplete="off" placeholder="请输入对私渠道返点"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            W渠道对公返点
                        </div>
                        <el-input v-model="form.w_channel_contrary_to_port_rebates" autocomplete="off" placeholder="请输入W渠道对公返点"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            W渠道对私返点
                        </div>
                        <el-input v-model="form.w_channel_private_port_rebates" autocomplete="off" placeholder="请输入W渠道对私返点"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            cid对公返点
                        </div>
                        <el-input v-model="form.cid_channel_contrary_to_port_rebates" autocomplete="off" placeholder="请输入cid对公返点"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            cid对私返点
                        </div>
                        <el-input v-model="form.cid_channel_private_port_rebates" autocomplete="off" placeholder="请输入cid对私返点"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="quxiao" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg", "id"],
    data() {
        return {
            yunyingtan: true, //操控弹窗展示
            form: {
                //表单信息
                advertiser_account: "", //端口账户
                advertiser_name: "", //端口简称
                advertiser_full_name: "", //端口全称
                sto_contrary_to_port_rebates: "", //对公自运营返点
                sto_private_port_rebates: "", //对私自运营返点
                goo_contrary_to_port_rebates: "", //对公代运营返点
                goo_private_port_rebates: "", //对私代运营返点
                channel_contrary_to_port_rebates: "", //对公渠道返点
                channel_private_port_rebates: "", //对私渠道返点
                w_channel_contrary_to_port_rebates:'',//W渠道对公返点
                w_channel_private_port_rebates:"",//W渠道对私返点
                cid_channel_contrary_to_port_rebates:'',//W渠道对公返点
                cid_channel_private_port_rebates:"",//W渠道对私返点
                advertiser_id: "",
                advertiser_pwd:""
            },
            formLabelWidth: "140px",
            activeName: "", //显示组件
            imageUrl: "", //头像
            fileListUpload: [], //上传的文件列表
            page: 1,
            inputD: "",
        };
    },
    mounted() {
        // console.log(this.id);
        this.getAdvertiserRes();
        // if (this.msg == Addport) {
        //     this.activeName == true;
        // }
    },
    methods: {
        getAdvertiserRes() {
            var param = {
                advertiser_id: this.id,
            };
            this.$service.get(this.$api.getAdvertiserRes, param, (res) => {
                if (res.code == "200") {
                    // console.log(res)
                    this.form = res.data;
                    delete this.form.id;
                }
            });
        },
        // 获取企业信息
        getTopCorporateAccountList() {
            var param = {
                page: this.page,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.inputD = res.data.data;
                        // console.log(this.inputD);
                    }
                }
            );
        },
        //选择企业触底事件
        load() {
            this.page += 1;
            var param = {
                page: this.page,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    var data = res.data.data;
                    if (data == "") {
                        this.$message({
                            message: "已没有更多数据",
                            type: "warning",
                        });
                        return;
                    }
                    if (res.code == "200") {
                        var inputD = this.inputD;
                        data.forEach(function (item, index) {
                            inputD.push(item);
                        });
                        this.inputD = inputD;
                    }
                }
            );
        },
        quxiao() {
            this.$emit("bianji");
            this.yunyingtan = false;
        },
        closeDialog() {
            this.$emit("bianji");
            this.yunyingtan = false;
        },
        tijiao() {
            if(this.form.advertiser_account == ''){this.$message({message: "请输入端口账户",type: "warning",}); return}
            if(this.form.advertiser_name == ''){this.$message({message: "请输入端口简称",type: "warning",}); return}
            if(this.form.advertiser_pwd == ''){this.$message({message: "请输入端口密码",type: "warning",}); return}
            if(this.form.sto_contrary_to_port_rebates == ''){this.$message({message: "请输入对公自运营返点",type: "warning",}); return}
            if(this.form.sto_private_port_rebates == ''){this.$message({message: "请输入对私自运营返点",type: "warning",}); return}
            if(this.form.goo_contrary_to_port_rebates == ''){this.$message({message: "请输入对公代运营返点",type: "warning",}); return}
            if(this.form.goo_private_port_rebates == ''){this.$message({message: "请输入对私代运营返点",type: "warning",}); return}
            if(this.form.w_channel_contrary_to_port_rebates == ''){this.$message({message: "请输入W渠道对公返点",type: "warning",}); return}
            if(this.form.w_channel_private_port_rebates == ''){this.$message({message: "请输入W渠道对私返点",type: "warning",}); return}
            if(this.form.cid_channel_contrary_to_port_rebates == ''){this.$message({message: "请输入cid对公返点",type: "warning",}); return}
            if(this.form.cid_channel_private_port_rebates == ''){this.$message({message: "请输入cid对私返点",type: "warning",}); return}
            var param = this.form;
            param.advertiser_id = this.id;
            // console.log(param);
            this.$service.post(this.$api.createAdvertiser, param, (res) => {
                if (res.code == "200") {
                    this.$message({message: "编辑成功",type: "success",});
                    this.$emit("bianji");
                    this.yunyingtan = false;
                }
            });
        },

        //上传文件移出
        onRemoveupload(file, fileList) {
            this.fileListUpload = fileList;
            this.file_id = "";
            this.$message({
                message: "文件移出成功，请重新上传文件",
                type: "success",
            });
        },
        //文件上传成功
        onSuccessupload(res, file, fileList) {
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            if (res.code == 200) {
                this.file_id = res.data.id;
                this.form.file_id = file.response.data.id;
                this.form.upload_img = file.response.data.file_url;
            }
        },
        //文件上传成功，失败 所有都会调用
        onChangeUpload(file, fileList) {
            // console.log(file, "文件上传成功，失败 所有都会调用");
        },
    },
};
</script>

<style lang="scss" scoped>
.Addport {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
        font-weight: bold;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    //
}
</style>
<style lang="scss">
.Addport {
    .upload-demo {
        width: 200px;
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    // .el-dialog {
    //     width: 35%;
    // }
}
</style>