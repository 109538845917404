<template>
	<el-dialog :title="settlementData.advertiser_full_name" custom-class='settlementDialog class-dialog' :visible.sync="Dialog" width="920px" height="500px" @close="onCancel" >
		<div class="dialog-inside">
			<el-table :data="FinancialList" style="width: 100%">
				<el-table-column prop="date" label="日期"> </el-table-column>
				<el-table-column prop="beginning_balance" label="期初余额">
					<template slot-scope="scope">
						<p>{{ThousandSeparator(scope.row.beginning_balance)}}</p>
						<p style="color:#999;font-size:12px">{{smallToBig(scope.row.beginning_balance)}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="for_the_amount" label="备款金额（元）">
					<template slot-scope="scope">
						<p>{{ThousandSeparator(scope.row.for_the_amount)}}
							<el-popover placement="bottom" width="300" popper-class="modifpopper" v-if="dateTime == scope.row.date" @hide="onPopoverDide">
								<div class="content">
									<p>增加备款金额</p>
									<p><el-input v-model="for_the_amount" :placeholder="scope.row.for_the_amount" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input></p>
								</div>
								<div style="text-align: center; margin: 0">
									<el-button type="primary" size="mini" @click="onReference(scope.row)" round>保存修改</el-button>
								</div>
								<i slot="reference" class="el-icon-edit" style="cursor: pointer;color:#0075f7"></i>
							</el-popover>
						</p>
						<p style="color:#999;font-size:12px">{{smallToBig(scope.row.for_the_amount)}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="today_order_actual_balance" label="充值金额">
					<template slot-scope="scope">
						<p>{{ThousandSeparator(scope.row.today_order_balance)}}</p>
						<p style="color:#999;font-size:12px">{{smallToBig(scope.row.today_order_balance)}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="today_order_actual_balance" label="充值广告币（元）">
					<template slot-scope="scope">
						<p>{{ThousandSeparator(scope.row.today_order_actual_balance)}}</p>
						<p style="color:#999;font-size:12px">{{smallToBig(scope.row.today_order_actual_balance)}}</p>
					</template>
				</el-table-column>
				<el-table-column prop="your_kind_balance" label="备款余额">
					<template slot-scope="scope">
						<p>{{ThousandSeparator(scope.row.your_kind_balance)}}</p>
						<p style="color:#999;font-size:12px">{{smallToBig(scope.row.your_kind_balance)}}</p>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="lv-page"  :page-size="per_page" :pager-count="11"  @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page></el-pagination>
		</div>
	</el-dialog>
</template>

<script>
export default {
	data(){
        return{
            Dialog:false,
			settlementData:{},
			FinancialList:[],
			per_page:10,//一页多少条
			lvtotal:null,//共几条数据
			for_the_amount	:'',//修改备款金额input
			dateTime:this.dateFtt("yyyy-MM-dd",new Date()),
        }
    },
	mounted(){

	},
    methods:{
		//打开弹窗
		onDialog(row){
			this.settlementData = row
			this.ongetTopFinancialList()
			this.Dialog = true
		},
		//翻页
		DialogCurrentChange(val){
			this.ongetOrderList(val)
		},
		ongetTopFinancialList(val){
			this.FinancialList = []
			var param = {
				page:val?val:1,
				advertiser_id:this.settlementData.id
			}
			this.$service.get(this.$api.getTopFinancialList,param, (res)=> {
				if(res.code == '200'){
					this.FinancialList = res.data.data
				}
			})
		},
		
		onReference(row){
			var param = {
				financial_id:row.id,
				for_the_amount:this.for_the_amount
			}
			this.$service.post(this.$api.updateTopFinancialForTheAmount,param, (res)=> {
				if(res.code == '200'){
					this.$message({message: '修改成功', type: 'success' })
					this.ongetTopFinancialList()
					this.for_the_amount = ''
				}
			})
		},
		//关闭增加时触发
		onPopoverDide(){
			this.for_the_amount	 = ''//修改备款金额input
		},
		//关闭弹窗触发
		onCancel(){
			this.settlementData = {},
			this.FinancialList = [],
			this.for_the_amount	 = '',//修改备款金额input
			this.dateTime = this.dateFtt("yyyy-MM-dd",new Date())
		}
    },

	

	// methods: {
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
	// }
}
</script>

<style lang="scss" scoped>

</style>
<style lang="scss">
.settlementDialog{
	min-height: 500px;
	.dialog-inside{
		padding-bottom: 20px;
	}
}
.modifpopper{
	.content{
		padding: 12px 20px;
		padding-bottom: 0;
		text-align: center;
		p{
			margin-bottom: 10px;
			.el-icon-edit{
			}
			.el-input{
				input{
					text-align: center !important;
				}
			}
		}
	}
}
</style>